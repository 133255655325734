import chris from './img/chris_biondo.png'
import lbm from './img/lbm.png'
import imdb from './img/imdb.png'
import logo from './img/logo.png'
import './App.css'

import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBtOmjFs7KIafsN2boRwjgQMNMwjVDd6UI",
  authDomain: "biondo-music.firebaseapp.com",
  projectId: "biondo-music",
  storageBucket: "biondo-music.appspot.com",
  messagingSenderId: "960641870905",
  appId: "1:960641870905:web:45981209c34e442f57b1e8"
};
const app = initializeApp(firebaseConfig);

function App () {
  const handleMailtoClick = () => {
    window.location.href =
      'mailto:chris@biondomusic.com'
  }

  return (
    <div className='App'>
      <div className='titleBar'>
        <img src={logo} className='logoHeader' alt='Biondo Music' />
      </div>
      <div className='header'>
      <img src={logo} className='logoHeaderMain' alt='Biondo Music' />
      </div>
      <div className='section section1'>
        <h1>Original music for television and film</h1>
        <br />
        <br />
        <span>
          Chris Biondo has composed over 500 original scores for documentary
          films, winning Emmy Awards in 2001, 2002, 2004, 2005 and 2014 in the
          category of Outstanding Individual Achievement in a Craft: Music and
          Sound. His music library, developed in collaboration with his partners
          in <a target="_blank" rel="noopener noreferrer" href='https://www.loadbearingmusic.com'>Load Bearing Music</a>, includes
          more than 3,000 unique, original cues in a wide range of genres and is
          continually growing; to date, his music has been heard on thousands of
          television programs. Major clients include Netflix, National
          Geographic Television and Film, the Discovery Channel, TLC, PBS,
          Animal Planet, Smithsonian Network, Fox Network, CNN, The History
          Channel, MSNBC, Disney and the BBC.
        </span>
      </div>
      <div className='section section1 split'>
        <img
          src={chris}
          alt='Chris Biondo'
          style={{
            maxWidth: '250px',
            alignSelf: 'flex-start'
          }}
        />
        <span>
          Before changing the focus of his career to music composition, Chris
          worked as a bassist, guitarist, keyboardist, recording engineer, and
          producer. A studio owner and recording engineer since 1983, he's
          worked with many local Washington, DC artists, including the legendary
          “Godfather of Go-Go” Chuck Brown. He has recorded well over 200 albums
          with styles as diverse as folk, rap, country, jazz, classical and
          rock, for labels including Motown, Columbia, Island, Def Jam, Sony and
          Virgin. In 1986 Chris began recording the then-unknown singer Eva
          Cassidy. For the next ten years he worked with Eva to develop her as a
          recording artist, producing most of her recordings available today. In
          the years since Eva's death in 1996, her recordings have sold more
          than 12 million copies and achieved international renown, including
          three albums that reached number one in the UK charts. Chris has
          received numerous Gold and Platinum records in the U.S. and
          internationally for his work with Eva.
          <br />
          <br />
          <strong>
            In 2015 Chris and Lenny Williams produced an album for Sony Records
            Recording Artist Susan Boyle.
          </strong>
        </span>
      </div>
      <div className='section section3 row'>
        <img src={lbm} alt='Load Bearing Music' />
        <span>
          Load Bearing Music Library is a music resource developed by composers
          Chris Biondo, Mac Squier, and Lenny Williams.
          <br /> See more at &nbsp;
          <a target="_blank" rel="noopener noreferrer" href='https://www.loadbearingmusic.com'>loadbearingmusic.com</a>
        </span>
      </div>
      <div className='section section2'>
        <h1>Select Projects</h1>
        <div className='split top'>
          <div className='lists'>
            <h2>Awards and Nominations</h2>

            <h4>Animal Misfits</h4>
            <p>
              Nature/PBS
              <br />
              <em>
                Emmy Award Winner, Outstanding Individual Achievement in a
                Craft: Music and Sound, 2014
              </em>
            </p>

            <h4>Deadly Love</h4>
            <p>
              National Geographic Television
              <br />
              <em>
                Emmy Award Winner, Outstanding Individual Achievement in a
                Craft: Music and Sound, 2005
              </em>
            </p>

            <h4>On Thin Ice</h4>
            <p>
              National Geographic Television
              <br />
              <em>
                Emmy Award Winner, Outstanding Individual Achievement in a
                Craft: Music and Sound, 2004
              </em>
            </p>

            <h4>Hornets from Hell</h4>
            <p>
              National Geographic Television
              <br />
              <em>
                Emmy Award Winner, Outstanding Individual Achievement in a
                Craft: Music and Sound, 2002
              </em>
            </p>

            <h4>Haunt of the Hippo</h4>
            <p>
              National Geographic Television
              <br />
              <em>
                Emmy Award Winner, Outstanding Individual Achievement in a
                Craft: Music and Sound, 2001
              </em>
            </p>

            <h4>An Original DUCKumentary</h4>
            <p>
              Nature/PBS
              <br />
              <em>
                Nominated, Outstanding Individual Achievement in a Craft: Music
                and Sound, 2012
              </em>
            </p>

            <h4>Untamed Americas</h4>
            <p>
              National Geographic Channel
              <br />
              <em>
                Nominated, Outstanding Individual Achievement in a Craft: Music
                and Sound, 2012
              </em>
            </p>

            <h4>Battle of the Arctic Giants</h4>
            <p>
              National Geographic Television
              <br />
              <em>
                Nominated, Outstanding Individual Achievement in a Craft: Music
                and Sound, 2004
              </em>
            </p>

            <h4>BMI TV Music Awards</h4>
            <ul>
              <li>Winner, 2003</li>
              <li>Winner, 2004</li>
              <li>Winner, 2006</li>
              <li>Winner, 2007</li>
            </ul>

            <h2>Recording Industry Awards</h2>

            <h4>
              Producer: <em>American Tune</em> (2003)
            </h4>
            <p>
              Eva Cassidy
              <br />
              #1 on UK Charts
              <br />
              UK Certified Gold
            </p>

            <h4>
              Producer: <em>Imagine</em> (2000)
            </h4>
            <p>
              Eva Cassidy
              <br />
              #1 on UK Charts
              <br />
              UK Certified Gold
            </p>

            <h4>
              Producer: <em>Songbird</em> (1998)
            </h4>
            <p>
              Eva Cassidy
              <br />
              #1 on UK Charts
              <br />
              USA Certified Gold, UK Certified Double Platinum
            </p>
          </div>
          <div className='lists'>
            <h2>Notable Projects</h2>
            <h4>Penguin Town (TV Series)</h4>
            <p>
              Netflix, Season 1, 2021
              <br />
              <em>
                Nominated, 2021 OUTSTANDING MUSIC DIRECTION AND COMPOSITION
              </em>
              <br />
              <em>Composers: Lenny Williams, Chris Biondo</em>
            </p>

            <h4>Nature (TV Series)</h4>
            <p>PBS, 16+ episodes, including:</p>
            <h4>An Original DUCKumentary (2012)</h4>
            <p>Emmy Award Winner, Outstanding Nature Programming, 2012</p>

            <h4>The Incredible Dr. Pol (TV Series)</h4>
            <p>
              Nat Geo WILD, 200+ episodes
              <br />
              Seasons 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, and 13
            </p>

            <h4>Rare: Creatures of the Photo Ark (TV Series)</h4>
            <p>
              PBS, 2021
              <br />5 episodes
            </p>

            <h4>World's Biggest Bullshark (Sharkweek)</h4>
            <p>Discovery Channel</p>

            <h4>Mystery Files (TV Series)</h4>
            <p>National Geographic International UK, 20 episodes</p>

            <h4>America's Wild Spaces (TV Series)</h4>
            <p>Nat Geo WILD, 8 episodes</p>

            <h4>National Geographic Ultimate Explorer (TV Series)</h4>
            <p>National Geographic Channel, 100+ episodes</p>

            <h4>Psychic Detectives (TV Series)</h4>
            <p>Court TV/TRUTV, 24+ episodes</p>

            <h4>Real Cowboys (TV Series)</h4>
            <p>History Channel, 4 episodes</p>

            <h4>Harvest of Empire (Movie)</h4>
            <p>2012</p>

            <h4>Angkor: Land of the Gods (TV Mini-Series Documentary)</h4>
            <p>Smithsonian Channel, 2011</p>

            <h4>Secret Weapon of the Confederacy (TV Movie Documentary)</h4>
            <p>National Geographic Channel, 2011</p>

            <h4>9/11: Where Were You? (TV Movie Documentary)</h4>
            <p>National Geographic Television, 2011</p>

            <h4>Undercover History (TV Series)</h4>
            <p>
              Lincoln: American Mastermind (2009)
              <br />
              National Geographic Channel
            </p>

            <h4>As We Forgive (Documentary Movie)</h4>
            <p>
              2008
              <br />
              Best Documentary Winner, Student Academy Awards, 2008
            </p>

            <h4>Secrets of the Zoo</h4>
            <p>National Geographic Wild, 36 episodes, 2020</p>

            <h4>Nefertiti and the Lost Dynasty (TV Movie Documentary)</h4>
            <p>National Geographic Channel, 2007</p>

            <h4>After Jesus: The First Christians</h4>
            <p>CNN Presents, 2006</p>

            <h4>Predators at War (TV Movie Documentary)</h4>
            <p>
              National Geographic Television, 2005
              <br />
              Emmy Award Winner, Outstanding Science, Technology and Nature
              Programming, 2005
            </p>

            <h4>Wolf Pack (TV Movie Documentary)</h4>
            <p>
              National Geographic Television/MSNBC, 2004
              <br />
              Emmy Award Winner, Outstanding Science, Technology and Nature
              Programming, 2004
            </p>

            <h4>Search for the Afghan Girl (TV Movie Documentary)</h4>
            <p>National Geographic Television, 2003</p>

            <div className='row'>
              <img src={imdb} alt='Imdb' />
              <p>
                See additional titles on &nbsp;
                <a target="_blank" rel="noopener noreferrer" href='https://www.imdb.com/name/nm1564664/'>imbd.com.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='section section1'>
        <h1>Samples</h1>
        <iframe src='https://biondo-music.web.app/'></iframe>
      </div>
      <div className='section'></div>
      <div className='section'></div>
      <div className='section'></div>
      <div className='section section1 footer'>
        <button onClick={handleMailtoClick} className='button'>
          CHRIS@BIONDOMUSIC.COM
        </button> 
        <span>
          Chris's studio in the Washington, DC suburb of Kensington, Maryland
          features a full recording studio with state-of-the-art digital
          capabilities. Services include original music composition and
          recording facilities for documentaries and feature films, advertising
          campaigns (tv and radio), corporate and promotional videos, radio
          spots, and voice over for narration.
        </span>{' '}
        <br /> <br /> <br />
        <span>
          Chris Biondo | Biondo Music Production LLC <br />
          4212-2B Howard Avenue <br />
          Kensington, MD 20895 <br />
          <a href='mailto:chris@biondomusic.com'>chris@biondomusic.com</a>{' '}
          <br />
        </span>{' '}
        <br /> <br />
        <span>
          ©2013 Chris Biondo | Biondo Music Production LLC. All Rights Reserved.
        </span>
        <br /> <br />
      </div>
    </div>
  )
}

export default App
